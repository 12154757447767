*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

:root {
    --neutral-very-light-grayish-blue: hsl(240, 78%, 98%);
    --neutral-light-grayish-blue: hsl(234, 14%, 74%);
    --neutral-grayish-blue: hsl(233, 13%, 49%);
    --neutral-dark-grayish-blue: hsl(232, 13%, 33%);
    --neutral-white: hsl(0, 0%, 100%);
}

.PricingApp {
    min-height: 100vh;
    display: grid;
    place-items: center;
    background-color: #161d26;
    z-index: 999;
}

.PricingApp::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 353px;
    width: 100%;
    height: 304px;
    /*background-image: url("../images/bg-bottom.svg");*/
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
}

/*toggle*/
.PricingApp .price-switch {
    --border-radius: 10em;

    border-radius: var(--border-radius);
    position: relative;
    cursor: pointer;
}

.PricingApp .price-checkbox {
    opacity: 0;
    height: 0;
    width: 0;
}

.PricingApp .switch-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 25px;
    border-radius: 10em;
    background-color: #10a6ce;
}

.PricingApp .switch-slider:is(:hover, :focus-visible) {
    opacity: 0.7;
}

.PricingApp .switch-slider::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 4px;
    width: 20px;
    border-radius: 50%;
    aspect-ratio: 1;
    background-color: var(--neutral-very-light-grayish-blue);
    transform: translateX(calc(100% + 2px));
    transition: 0.3s ease;
}

.PricingApp .price-checkbox:checked + .switch-slider::before {
    transform: translateX(0%);
}


@media screen and (max-width: 800px) {
    .PricingApp {
        padding-block: 4em;
    }
}