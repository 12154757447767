@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --bg: #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1;
    --nav-size: 60px;
    --border: 1px solid #474a4d;
    --border-radius: 8px;
    --speed: 500ms;
}


.navbar {
    height: var(--nav-size);
    background-color: var(--bg);
    padding: 0 1rem;
    border-bottom: var(--border);
}

/* <ul> */
.navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

/* <li> */
.nav-item {
    width: calc(var(--nav-size) * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Icon Button */
.icon-button {
    --button-size: calc(var(--nav-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: #484a4d;
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
}

.icon-button:hover {
    filter: brightness(1.2);
}

.icon-button svg {
    fill: var(--text-color);
    width: 20px;
    height: 20px;
}


/* Dropdown Menu */

.dropdown {
    position: absolute;
    top: 58px;
    width: 300px;
    transform: translateX(-45%);
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 1rem;
    overflow: hidden;
    transition: height var(--speed) ease;
}

.menu {
    width: 100%;
}

.menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.5rem;
}

.menu-item .icon-button {
    margin-right: 0.5rem;
}


.menu-item .icon-button:hover {
    filter: none;
}

.menu-item:hover {
    background-color: #525357;
}

.icon-right {
    margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
}

.menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}

.menu-primary-exit {
    position: absolute;
}

.menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
}


.menu-secondary-enter {
    transform: translateX(110%);
}

.menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}

.menu-secondary-exit {

}

.menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
}


body {
    background-color: #1d1d1d;
}

input[type="text"]:focus ~ .input-text,
input[type="text"]:not(:placeholder-shown) ~ .input-text {
    @apply text-[#10a7d0] transform -translate-y-10 -translate-x-2 scale-75;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
.app,
.content {
    height: 100%;
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
}

.app {
    display: flex;
    position: relative;
}

:root {
    --transparent-black: rgba(0, 0, 0, 0.294);
    --black: #1d1d1d;
    --white: #ffffff;
    --grey: rgba(255, 255, 255, 0.597);
    --orange: #05ebfd;
    --green: rgb(255, 255, 255);
    --lightBlue: #10a7d0;
    --darkBlue: #1b235b;
    --blueGradient: linear-gradient(
            268.35deg,
            rgb(27, 36, 92) 3.47%,
            rgb(59, 114, 210) 60.18%
    );
}

* {
    box-sizing: border-box;
}

#dashboard {
    background-color: #1d1d1d;
    color: white;
    font-family: "Public Sans", sans-serif;

}

.theme-container {
    background: rgba(0, 0, 0, 0.59);
    border-radius: 20px;
}

.gradient-blue-one {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 200px;
    height: 200px;
    background: #1c3051;
    filter: blur(90px);
}

.gradient-blue {
    position: absolute;
    width: 200px;
    height: 200px;
    background: #5dc9ff;
    filter: blur(120px);
}

.grey-container {
    border-top: 0.3px solid rgba(255, 255, 255, 0.185);
    border-bottom: 0.3px solid rgba(255, 255, 255, 0.185);
    background: linear-gradient(
            90deg,
            rgba(53, 53, 53, 0) 1.21%,
            rgba(53, 53, 53, 0.46) 49.45%,
            rgba(53, 53, 53, 0) 103.83%
    );
    padding: 1.5rem 1rem;
}

::-webkit-scrollbar {
    width: 0.6em;
    height: 0.6em;
}


::-webkit-scrollbar-thumb {
    background: #10a7d0;
    border-radius: 100vw;
}
