* {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

#root {
    color: #ffff;
}

.home {
    background: #000001;
    font-weight: 600;
    font-size: 1.125rem;
    color: #ffff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

a {
    color: #ffff;
    text-decoration: none;
}

.zoom {
    opacity: 1;
    transform: scale(100%);
    transition: 2s;
}

@media only screen and (min-width: 768px) {
    .hamburger {
        display: none;
    }
}

.menu .menu-links a {
    margin: 12px;
    color: #ffff;
    text-decoration: none;
}

button {
    border-radius: 10px;
    padding: 10px 20px;
    border: none;
    text-transform: uppercase;
}

button.primary {
    color: white;
    background: linear-gradient(to right, #1a2158 0%, #3c74d6 100%);
}

span.highlighted {
    text-transform: uppercase;
    background: linear-gradient(to right, #1a2158 0%, #3c74d6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

p {
    font-size: 1.5rem;
}

.feature-container {
    color: #9f9fc8;
    width: 75%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 17px;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    margin-top: 50px;
}

@media only screen and (max-width: 768px) {
    .feature-container {
        flex-direction: column;
    }
}

.swap-token-container {
    color: #fff;
    height: 100%;
    background-color: #070711;
    border: 1px solid;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 40px;
    font-weight: 700;
}

.tokens-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-basis: 56%;
    margin-top: 3px;;
    flex-direction: column;
}

.token-container {
    width: 100%;
    background-color: #23235b;
    border-radius: 10px;
    display:flex;
    flex-direction: row;
    flex-basis: 45%;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    padding: 20px 20px 20px 0;
    margin: 5px 0;
}

.token-info {
    height: 50%;
    border-radius: 0 20px 20px 0;
    background-color:#1d1d48;
    display: flex;
    flex-basis: 42%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
    margin-right: 2px;
    padding-left: 7px;
    line-height: 30px;
}

.token-logo {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-left: 1px;
}

.token-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2px;
}

.token-title-container .token-title {
    text-transform: uppercase;
    font-size: 1.5rem;
}

.token-title-container .token-title-long {
    color: #9f9fc8;
    font-weight: 700;
    line-height: 21px;
}

.swap-token-container .btn-container {
    display: flex;
    width: 90%;
    margin-top: 5px;
}

.swap-token-container .btn-container button {
    width: 100%;
}

.feature-container .feature-description {
    height: 85%;
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 17px;
    margin-bottom: 12px;
    font-size: 1.5rem;
    line-height: 49px;
}

.feature-container .feature-description .feature-description-title {
    color: #fff;
    width: 80%;
}
.feature-container .feature-description .feature-description-paragraph {
    line-height: 28px;
}

.gradient-border {
    background: linear-gradient(#070711 0 0 ) padding-box, linear-gradient(to right, transparent, #30bddc) border-box;
    border-radius: 10px;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border: double 1px transparent;
}

.daily-stats-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    color: #9f9fc8;
    width: 75%;
    background-color: #131330;
    border-radius: 10px;
    font-weight: 500;
    line-height: 43px;
    margin-top: 30px;
}

@media only screen and (max-width: 768px) {
    .daily-stats-container {
        flex-direction: column;
    }
}

.daily-stats-container .metric-container {
    display: flex;
    padding: 18px;
    color: #fff;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.daily-stats-container .metric-container .metric-title {
    color: #9f9fc8;
    font-weight: bold;
    font-size: 18px;
}

.daily-stats-container .metric-container .metric-value {
    align-self: flex-end;
    font-size: 36px;
    font-weight: medium;
}

.features-list-title {
    margin-bottom: 10px;
    text-align: left;
    width: 75%;
}

.social-media-promotion-icons {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .social-media-promotion-icons {
        flex-direction: column;
    }
}

.social-media-promotion-icons a {
    margin: 0 10px;
}

.footer-container {
    width: 100%;
    display: flex;
    background-color: #131330;
}

@media only screen and (max-width: 768px) {
    .footer-container {
        padding: 10px;
    }
}

.footer-container .footer-copyright {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.hamburger svg {
    width: 20px;
    height: 20px;
}

.hamburger {
    background: none;
    border: none;
    padding: 0;
}

.hamburger path {
    fill: white;
}

input {
    background: rgba(0, 0, 0, 0.59);
    border: 1px solid #fff;
    font-size: 1rem;
    padding: 1rem;
    border-radius: 5px;
    color:#fff;
}